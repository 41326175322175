<template>

  <iframe id="inlineFrameExample"
    :src="'https://rhino.sens-eye.fr/examples/lemtosh/?id_scan='+scan.id+'&token='+token" />
</template>

<script>
import {BModal, BButton,BOverlay, VBModal, BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BRow
  },
  data() {
    return {
      token:localStorage.token_senseye_auth
    }
  },
  props:['scan'],
  
  methods:{

  },
  watch:{
    
  },
  async mounted()
  {
   
  }
}
</script>

<style lang="scss" >
#inlineFrameExample {
    width:100%;
    height:100vh;
    border:0;
}
</style>
